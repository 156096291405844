// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/App.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-countdown-js": () => import("./../../../src/pages/Countdown.js" /* webpackChunkName: "component---src-pages-countdown-js" */),
  "component---src-pages-expired-js": () => import("./../../../src/pages/expired.js" /* webpackChunkName: "component---src-pages-expired-js" */),
  "component---src-pages-free-ebook-js": () => import("./../../../src/pages/free-ebook.js" /* webpackChunkName: "component---src-pages-free-ebook-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

